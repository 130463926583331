import React, { Fragment } from "react"
import {
  H4,
  SummaryItemLabel,
  SummaryItem,
  SummaryGroupLabel,
  Paragraph2,
} from "@styles/Global.styles"
import { Grid } from "semantic-ui-react"
import theme from "@styles/theme"
import {
  DetailSectionColumn,
  SummaryItemContainer,
  SummaryItemContainerRow,
  SummaryEditLink,
  DetailSectionHeading,
} from "./OrderReviewDetails.styles"
import { Checkout } from "@models/Checkout/Checkout"
import { OrderReviewSummaryData } from "./mapper"
import moment from "moment"
import { formattedAddress } from "@utils/Helpers"
import DealerOrderReviewDetails from "@components/Dealer/DealerOrderReviewDetails"
import { MininumDirverLicenseAge } from "@utils/Constant"
import KpButton from "@elements/KpButton/KpButton"
import { BasketResponseModel } from "@models/Basket/BasketResponseModel"

interface OrderReviewProps {
  checkout: Checkout
  data: OrderReviewSummaryData
}
interface OrderReviewStates { }

class MobileOrderReviewDetails extends React.Component<
  OrderReviewProps,
  OrderReviewStates
  > {
  render() {
    const { checkout, data } = this.props
    const compareDob = moment().add(-MininumDirverLicenseAge, 'year');
    const isValidDob = moment(new Date(checkout.vehicleDetails.dob == undefined ? new Date() : checkout.vehicleDetails.dob.toString())).isBefore(compareDob)

    return (
      <Grid>
        <DetailSectionHeading width={16}>
          <H4>Order Details</H4>
        </DetailSectionHeading>
        {checkout.isDealerOrder ?
        <DealerOrderReviewDetails checkout={checkout} data={data}/>
        :
        <>
          <DetailSectionColumn width={16}>
          <Grid>
            {!checkout.isMultiPlate && (
              <Fragment>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <SummaryGroupLabel fontSize={theme.mobileTheme.titleFontSize} color={theme.mobileTheme.color}>
                      Owner Details - {checkout.ownershipType}
                    </SummaryGroupLabel>
                    <SummaryGroupLabel
                      color={theme.mobileTheme.color}
                      align="right"
                    >
                      <SummaryEditLink to="/personal-details/">
                        Edit
                      </SummaryEditLink>
                    </SummaryGroupLabel>
                  </Grid.Column>
                </Grid.Row>

                <SummaryItemContainer mobile={16} tablet={16} computer={8}>
                  <SummaryItemLabel>Full Name</SummaryItemLabel>
                  <SummaryItem data-private>
                    {checkout.ownerDetails.firstName}{" "}
                    {checkout.ownerDetails.lastName}
                  </SummaryItem>
                </SummaryItemContainer>
                <SummaryItemContainer mobile={16} tablet={16} computer={8}>
                  <SummaryItemLabel>Email</SummaryItemLabel>
                  <SummaryItem data-private>
                    {checkout.ownerDetails.email}
                  </SummaryItem>
                </SummaryItemContainer>
                <SummaryItemContainer mobile={16} tablet={16} computer={8}>
                  <SummaryItemLabel>Phone</SummaryItemLabel>
                  <SummaryItem data-private>
                    {checkout.ownerDetails.phone}
                  </SummaryItem>
                </SummaryItemContainer>
                {checkout.manufactureNow && (
                  <Fragment>
                    <SummaryItemContainer mobile={16} tablet={16} computer={8}>
                      <SummaryItemLabel>Driver License</SummaryItemLabel>
                      <SummaryItem data-private>
                        {checkout.vehicleDetails.driverLicense}
                      </SummaryItem>
                    </SummaryItemContainer>
                    {checkout.vehicleDetails.dob && isValidDob && (
                      <SummaryItemContainer
                        mobile={16}
                        tablet={16}
                        computer={8}
                      >
                        <SummaryItemLabel>DOB</SummaryItemLabel>
                        <SummaryItem data-private>
                          {moment(checkout.vehicleDetails.dob).format(
                            "DD/MM/YYYY"
                          )}
                        </SummaryItem>
                      </SummaryItemContainer>
                    )}
                  </Fragment>
                )}
                {checkout.manufactureNow && (
                  <SummaryItemContainer mobile={16} tablet={16} computer={8}>
                    <SummaryItemLabel>Address</SummaryItemLabel>
                    <SummaryItem data-private>
                      {formattedAddress(checkout.ownerAddress)}
                    </SummaryItem>
                  </SummaryItemContainer>
                )}
              </Fragment>
            )}

            {(!checkout.ownerIsApplicant || checkout.isMultiPlate) && (
              <Fragment>
                <Grid.Row>
                  <SummaryItemContainer width={16}>
                    <SummaryGroupLabel fontSize={theme.mobileTheme.titleFontSize} color={theme.mobileTheme.color}>Your Details</SummaryGroupLabel>
                    {checkout.isMultiPlate && (
                      <SummaryGroupLabel
                        color={theme.mobileTheme.color}
                        align="right"
                      >
                        <SummaryEditLink to="/personal-details/">
                          Edit
                        </SummaryEditLink>
                      </SummaryGroupLabel>
                    )}
                  </SummaryItemContainer>
                </Grid.Row>
                <SummaryItemContainerRow>
                  <SummaryItemContainer mobile={16} tablet={16} computer={8}>
                    <SummaryItemLabel>Name</SummaryItemLabel>
                    <SummaryItem data-private>
                      {checkout.applicantDetails.firstName}{" "}
                      {checkout.applicantDetails.lastName}
                    </SummaryItem>
                  </SummaryItemContainer>
                </SummaryItemContainerRow>
                <SummaryItemContainerRow>
                  <SummaryItemContainer mobile={16} tablet={16} computer={8}>
                    <SummaryItemLabel>Email</SummaryItemLabel>
                    <SummaryItem data-private>
                      {checkout.applicantDetails.email}
                    </SummaryItem>
                  </SummaryItemContainer>
                </SummaryItemContainerRow>
                <SummaryItemContainerRow>
                  <SummaryItemContainer mobile={16} tablet={16} computer={8}>
                    <SummaryItemLabel>Phone</SummaryItemLabel>
                    <SummaryItem data-private>
                      {checkout.applicantDetails.phone}
                    </SummaryItem>
                  </SummaryItemContainer>
                </SummaryItemContainerRow>
              </Fragment>
            )}
            {checkout.manufactureNow && !checkout.isMultiPlate && (
              <Fragment>
                <SummaryItemContainerRow>
                  <SummaryItemContainer mobile={16} tablet={16} computer={8}>
                    <SummaryItemLabel>Existing Plate</SummaryItemLabel>
                    <SummaryItem data-private>
                      {checkout.vehicleDetails.existingCombination}
                    </SummaryItem>
                  </SummaryItemContainer>
                </SummaryItemContainerRow>
                <SummaryItemContainerRow>
                  <SummaryItemContainer mobile={16} tablet={16} computer={8}>
                    <SummaryItemLabel>Make</SummaryItemLabel>
                    <SummaryItem data-private>
                      {checkout.vehicleDetails.vehicleMake}
                    </SummaryItem>
                  </SummaryItemContainer>
                </SummaryItemContainerRow>
                <SummaryItemContainerRow>
                  <SummaryItemContainer mobile={16} tablet={16} computer={8}>
                    <SummaryItemLabel>Model</SummaryItemLabel>
                    <SummaryItem data-private>
                      {checkout.vehicleDetails.vehicleModel}
                    </SummaryItem>
                  </SummaryItemContainer>
                </SummaryItemContainerRow>
              </Fragment>
            )}
          </Grid>
        </DetailSectionColumn>
        {checkout.manufactureNow && !checkout.isMultiPlate && (
          <DetailSectionColumn width={16}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <SummaryGroupLabel fontSize={theme.mobileTheme.titleFontSize} color={theme.mobileTheme.color}>Delivery Details</SummaryGroupLabel>
                  <SummaryGroupLabel
                    color={theme.mobileTheme.color}
                    align="right"
                  >
                    <SummaryEditLink to="/delivery-address-selection/">
                      Edit
                    </SummaryEditLink>
                  </SummaryGroupLabel>
                </Grid.Column>
              </Grid.Row>
              <SummaryItemContainer mobile={16} tablet={16} computer={8}>
                <SummaryItemLabel>Name</SummaryItemLabel>
                <SummaryItem data-private>
                  {checkout.deliveryAddress &&
                    checkout.deliveryAddress.firstName ? (
                      <span>
                        {checkout.deliveryAddress.firstName}{" "}
                        {checkout.deliveryAddress.lastName}
                      </span>
                    ) : (
                      <span>
                        {checkout.ownerDetails.firstName}{" "}
                        {checkout.ownerDetails.lastName}
                      </span>
                    )}
                </SummaryItem>
              </SummaryItemContainer>
              <SummaryItemContainer mobile={16} tablet={16} computer={8}>
                <SummaryItemLabel>Address</SummaryItemLabel>
                <SummaryItem data-private>
                  {checkout.deliveryAddress &&
                    checkout.deliveryAddress.firstName
                    ? formattedAddress(checkout.deliveryAddress)
                    : formattedAddress(checkout.ownerAddress)}
                </SummaryItem>
              </SummaryItemContainer>
            </Grid>
          </DetailSectionColumn>
        )}
        <DetailSectionColumn width={16}>
          {checkout.manufactureNow && !checkout.isMultiPlate ? (
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <SummaryGroupLabel fontSize={theme.mobileTheme.titleFontSize} color={theme.mobileTheme.color}>Manufacture Now</SummaryGroupLabel>
                  <SummaryGroupLabel
                    color={theme.brand.colors.darkGrey}
                    align="right"
                  >
                    <SummaryEditLink to="/plate-manufacture-selection/">
                      Edit
                    </SummaryEditLink>
                  </SummaryGroupLabel>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Paragraph2 color={theme.brand.colors.black}>
                    {this.props.data.manunow}
                  </Paragraph2>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          ) : (
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <SummaryGroupLabel fontSize={theme.mobileTheme.titleFontSize} color={theme.mobileTheme.color}>{checkout.giftingType > 0 ? "Manufacture" : "Manufacture Later"}</SummaryGroupLabel>
                    {this.props.checkout.ownerIsApplicant &&
                      !this.props.checkout.isMultiPlate && (
                        <SummaryGroupLabel
                          color={theme.mobileTheme.color}
                          align="right"
                        >
                          <SummaryEditLink to="/plate-manufacture-selection/">
                            Edit
                        </SummaryEditLink>
                        </SummaryGroupLabel>
                      )}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    {checkout.ownerIsApplicant && checkout.isMultiPlate
                      ? this.props.data.manulater_multiplate
                      : checkout.ownerIsApplicant && checkout.isLayby
                        ? this.props.data.manulater_layby
                        : !checkout.ownerIsApplicant || checkout.isGift
                          ? this.props.data.manulater_gift
                          : checkout.giftingType > 0 
                          ? this.props.data.gift_a_plate
                          : this.props.data.manulater}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
        </DetailSectionColumn></>}
        <Grid.Column width={16}>
          <Grid reversed="mobile vertically">
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={8}
              textAlign="right"
            >
              <KpButton
                id="pdf-next"
                fullWidth="mobile"
                loading={false}
                buttonType="primary"
                color={theme.mobileTheme.buttonBgColor}
                textColor={theme.mobileTheme.buttonColor}
                type="button"
                link="/review-payment/#payment-options"
              >
                GO TO PAYMENT
            </KpButton>
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
    )
  }
}

export default MobileOrderReviewDetails
