import React from "react"
import { graphql } from "gatsby";
import { Layout } from "@components/Layout";
import { mapToSEO, SEO } from "@components/SEO";
import { Grid } from "semantic-ui-react";
import OrderSummary from "@components/OrderSummary/OrderSummary";
import theme from "@styles/theme";
import { GenericBannerContainer } from "@components/GenericBannerContainer";
import { H2 } from "@styles/Global.styles";
import { OrderReviewDetails, mapToOrderReviewSummary } from "@components/OrderReviewDetails";
import { FullPayment } from "@components/MakePayment";

import { Record } from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from "redux"
import { currentCheckoutSelector } from "@redux/selectors/checkoutSelector";
import { Address, Checkout } from "@models/Checkout/Checkout";
import { updatePaymentType, updateIsLayby, clearBasketAndCheckout, updateLaybyQuote, updateIsAutopay, updateLaybyPrefferedPaymentDay, clearBasket, updateIsAcceptStatement } from "@redux/actions/checkout";
import { currentBasketSelector } from "@redux/selectors/basketSelector";
import { BasketResponseModel } from "@models/Basket/BasketResponseModel";
import { OrderReviewSummaryData } from "@components/OrderReviewDetails/mapper";
import { LaybyQuote } from "@models/index";
import MobileFullPayment from "@components/Mobile/MakePayment/FullPayment";
import MobileOrderReviewDetails from "@components/Mobile/OrderReviewDetails/OrderReviewDetails";
import { isMobile } from "@utils/Helpers";
import { ReviewOrderSection } from "@utils/Constant";
import { NavHeader } from "@components/Mobile/NavHeader/NavHeader";
import MobileGenericBannerContainer from "@components/Mobile/GenericBannerContainer/GenericBannerContainer";
interface ReviewPaymentProps {
  location: any,
  data: any,
  checkout: Checkout,
  basket: BasketResponseModel,
  updatePaymentType: (paymentType: number) => void
  updateIsLayby: (isLayby: boolean) => void
  updateLaybyPreferredPaymentDay: (day: string) => void
  clearBasketAndCheckout: () => void
  updateLaybyQuote: (laybyQuote: LaybyQuote) => void
  updateIsAutopay: (isAutopay: boolean) => void
  clearBasket: () => void
  updateIsAcceptStatement:(isAcceptStatement: boolean) => void
}

interface ReviewPaymentStates {
}


class ReviewPaymentPage extends React.Component<ReviewPaymentProps, ReviewPaymentStates> {
  isRetryPayment = (location: any) => {
    const state = location.state;
    if (state && state.allowRetry) {
      return state.allowRetry;
    } else {
      return false;
    }
  }
  render() {
    const { checkout, basket, updatePaymentType, clearBasket, clearBasketAndCheckout, updateLaybyQuote, updateIsLayby, updateIsAutopay, updateLaybyPreferredPaymentDay, updateIsAcceptStatement } = this.props;
    const { elements } = this.props.data.kontentItemReviewPaymentPage;
    const apiUrl = this.props.data.site.siteMetadata.apiUrl;
    const seo = mapToSEO(elements);
    const heading = elements.heading.value;
    const data: OrderReviewSummaryData = mapToOrderReviewSummary(elements);
    const showCCSurcharge = checkout.paymentType === 1 ? true : false;
    const isRetryPayment = this.isRetryPayment(this.props.location);
    var hash = typeof window !== `undefined` ?  window.location.hash.substring(1) || ReviewOrderSection.OrderDetails : "";

    if(isMobile() === undefined){
      return null;
    }

    return (
      <Layout version="simple" currentStep={4} location={this.props.location}>
        <SEO {...seo} />
        <MobileGenericBannerContainer
          padding={{
            mobile: {
              top: 20,
              bottom: 40
            },
            desktop: {
              top: 40,
              bottom: 60
            }
          }}
          backgroundColor={isMobile() ? theme.mobileTheme.bgColor : theme.brand.colors.beige}>
          <Grid stackable reversed='mobile tablet vertically'>
            <Grid.Column mobile={16} tablet={16} computer={10}>
              <Grid>
                <Grid.Column width={16}>
                  {isMobile() ?
                  hash.includes(ReviewOrderSection.OrderDetails) &&
                  <React.Fragment>
                    <Grid.Column width={16}>
                      <H2>Review order</H2><br/>
                    </Grid.Column>
                    <OrderSummary isExpanded={false} showSurcharge={showCCSurcharge} isLayby={checkout.isLayby} laybyQuote={checkout.laybyQuote} basket={this.props.basket} apiUrl={apiUrl} />
                    <MobileOrderReviewDetails checkout={checkout} data={data}/>
                  </React.Fragment>
                  :
                  <OrderReviewDetails checkout={checkout} data={data}/>}
                </Grid.Column>
                <Grid.Column width={16}>
                  {isMobile() ?
                  hash.includes(ReviewOrderSection.PaymentOptions) &&
                  <React.Fragment>
                    <Grid.Column width={16}>
                      <H2>Select your payment type</H2><br/>
                    </Grid.Column>
                    <MobileFullPayment
                      apiUrl={apiUrl}
                      updateLaybyPreferredPaymentDay={updateLaybyPreferredPaymentDay}
                      updateIsAutopay={updateIsAutopay}
                      updateIsLayby={updateIsLayby}
                      updateLaybyQuote={updateLaybyQuote}
                      clearBasketAndCheckout={clearBasketAndCheckout}
                      clearBasket={clearBasket}
                      checkout={checkout}
                      data={data}
                      basket={basket}
                      updatePaymentType={updatePaymentType}
                      isRetryPayment={isRetryPayment}
                      updateIsAcceptStatement={updateIsAcceptStatement}/>
                  </React.Fragment>
                  :
                  <FullPayment
                    apiUrl={apiUrl}
                    updateLaybyPreferredPaymentDay={updateLaybyPreferredPaymentDay}
                    updateIsAutopay={updateIsAutopay}
                    updateIsLayby={updateIsLayby}
                    updateLaybyQuote={updateLaybyQuote}
                    clearBasketAndCheckout={clearBasketAndCheckout}
                    clearBasket={clearBasket}
                    checkout={checkout}
                    data={data}
                    basket={basket}
                    updatePaymentType={updatePaymentType}
                    isRetryPayment={isRetryPayment}
                    updateIsAcceptStatement={updateIsAcceptStatement}/>}
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={5} floated='right'>
                {isMobile() &&
                <NavHeader link={checkout.manufactureNow ? "/delivery-address-selection" : "/personal-details/#details"} info="Review"/>}
              </Grid.Column>
          </Grid>
        </MobileGenericBannerContainer>
      </Layout>);

  }
}

const mapStateToProps = (state: Record<any>) => {
  const checkout = currentCheckoutSelector(state).toJS();
  const basket = currentBasketSelector(state).toJS();
  return {
    checkout: checkout,
    basket: basket
  };
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updatePaymentType(paymentType: number) {
    dispatch(updatePaymentType(paymentType));
  },
  updateIsLayby(isLayby: boolean) {
    dispatch(updateIsLayby(isLayby));
  },
  clearBasketAndCheckout() {
    dispatch(clearBasketAndCheckout())
  },
  clearBasket(){
    dispatch(clearBasket())
  },
  updateLaybyQuote(laybyQuote: LaybyQuote) {
    dispatch(updateLaybyQuote(laybyQuote))
  },
  updateLaybyPreferredPaymentDay(paymentDay: string) {
    dispatch(updateLaybyPrefferedPaymentDay(paymentDay))
  },
  updateIsAutopay(isAutopay: boolean) {
    dispatch(updateIsAutopay(isAutopay))
  },
  updateIsAcceptStatement(isAcceptStatement: boolean) {
    dispatch(updateIsAcceptStatement(isAcceptStatement));
  }
})
const ReviewPaymentPageContainer = connect(mapStateToProps, mapDispatchToProps)(ReviewPaymentPage);
export default ReviewPaymentPageContainer

export const query = graphql`  {
  site {
    siteMetadata {
      apiUrl
    }
  }
    kontentItemReviewPaymentPage {
      elements {
        heading {
          value
        }
        seo__nofollow {
          value {
            codename
            name
          }
        }
        seo__noindex {
          value {
            codename
            name
          }
        }
        seo__page_description {
          value
        }
        seo__page_title {
          value
        }
        url{
          value
        }
        page_info___checkout__title {
          value
        }
        page_info___checkout__progress {
          value {
            codename
            name
          }
        }
        credit_card_note {
          value
        }
        a2a_note {
          value
        }
        credit_card_layby_note{
          value
        }
        a2a_layby_note{
          value
        }
        manufacture_now_description {
          value
        }
        manufacture_later_description {
          value
        }
        manufacture_later_description_layby {
          value
        }
        manufacture_later_description_multiplate {
          value
        }
        manufacture_later_description_gift {
          value
        }
        gift_a_plate{
          value
        }
        standard_t_c{
          value
        }
        manufacture_later_t_c{
          value
        }
        multiorder_t_c{
          value
        }
        afterpay_t_c {
          value
        }
        afterpay_note {
          value
        }
        gem_note {
          value
        }
        layby_autopay_t_c {
          value
        }
        a2a_disable_note {
          value
        }
        afterpay_disable_note {
          value
        }
        layby_disable_note {
          value
        }
        layby_info{
          value
        }
        dealer_payment_request_note {
          value
        }
        dealer_has_authority_t_c {
          value
        }
        dealers_t_c {
          value
        }
        dealers_customer_t_c {
          value
        }
        dealer_payment_request_t_c {
          value
        }
        manufacture_later_for_dealer {
          value
        }
      }
    }
  }

  `